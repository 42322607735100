import React from 'react';
import { Script } from 'gatsby';

const Analytics = () => {
  return process.env.GATSBY_PLAUSIBLE_URL &&
    process.env.GATSBY_PLAUSIBLE_DOMAIN ? (
    <Script
      id="auroramalet-analytics"
      type="plain/text"
      data-trucookiecontrol="statistics"
      data-domain={process.env.GATSBY_PLAUSIBLE_DOMAIN}
      src={process.env.GATSBY_PLAUSIBLE_URL}
    />
  ) : (
    <></>
  );
};

export default Analytics;
