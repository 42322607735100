import React from 'react';
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import useI18n from '../../../hooks/useI18n';
import dateToString from '../../../utils/dateToString';
import type Directus from '../../../types/directus';
import * as style from './style.module.scss';

export interface IHeroProps {
  title: string;
  subtitle?: string;
  date?: string;
  image?: {
    data: Directus.Image;
    alt: string;
  };
}

const Hero = ({ title, subtitle, date, image }: IHeroProps) => {
  const { language } = useI18n();

  return (
    <header className={style.header}>
      {date && (
        <h4 className={style.header__postDate}>
          <span>{dateToString(date, language)}</span>
        </h4>
      )}
      <h1 className={style.header__title}>
        <span>{title}</span>
      </h1>
      {image && (
        <GatsbyImage
          className={style.header__image}
          image={getImage(image.data) as IGatsbyImageData}
          alt={image.alt || ''}
        />
      )}
      {subtitle && (
        <h2 className={style.header__subTitle}>
          <span>{subtitle}</span>
        </h2>
      )}
    </header>
  );
};

export default Hero;
