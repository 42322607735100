import React, { createContext, ReactNode } from 'react';
import { DEFAULT_LANGUAGE, Languages } from '../constants/languages';

export interface I18nContextProps {
  language: Languages;
  alternates?: {
    [key: string]: string;
  };
}

export const I18nContext = createContext<I18nContextProps>({
  language: DEFAULT_LANGUAGE,
});

export interface I18nContextProviderProps {
  language: Languages;
  alternates: {
    [key: string]: string;
  };
  children: ReactNode;
}

export const I18nProvider = ({
  language,
  alternates,
  children,
}: I18nContextProviderProps) => {
  return (
    <I18nContext.Provider value={{ language, alternates }}>
      {children}
    </I18nContext.Provider>
  );
};

export interface I18nConsumerProps {
  children: ({ language }: I18nContextProps) => ReactNode;
}

export const I18nConsumer = ({ children }: I18nConsumerProps) => (
  <I18nContext.Consumer>{children}</I18nContext.Consumer>
);
