import React, { ReactNode } from 'react';
import * as style from './style.module.scss';

export interface IContainerProps {
  id?: string;
  children: ReactNode;
  tag?: keyof HTMLElementTagNameMap;
  width?: 'wide' | 'full';
  align?: 'left' | 'center' | 'right';
}

const Container = ({
  id,
  children,
  tag,
  width,
  align,
}: IContainerProps) => {
  const ContainerType = tag ?? 'div';

  let widthClass = '';
  switch (width) {
    case 'wide':
      widthClass = style.wide;
      break;
    case 'full':
      widthClass = style.full;
      break;
    default:
  }

  let alignClass = '';
  switch (align) {
    case 'center':
      alignClass = style.center;
      break;
    case 'right':
      alignClass = style.right;
      break;
    case 'left':
      alignClass = style.left;
      break;
    default:
  }

  return (
    <ContainerType
      id={id}
      className={`${style.container} ${widthClass} ${alignClass}`}
    >
      {children}
    </ContainerType>
  );
};

export default Container;
