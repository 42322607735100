exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integritetspolicy-tsx": () => import("./../../../src/pages/integritetspolicy.tsx" /* webpackChunkName: "component---src-pages-integritetspolicy-tsx" */),
  "component---src-pages-juridik-tsx": () => import("./../../../src/pages/juridik.tsx" /* webpackChunkName: "component---src-pages-juridik-tsx" */),
  "component---src-pages-nyheter-tsx": () => import("./../../../src/pages/nyheter.tsx" /* webpackChunkName: "component---src-pages-nyheter-tsx" */),
  "component---src-pages-om-aurora-tsx": () => import("./../../../src/pages/om-aurora.tsx" /* webpackChunkName: "component---src-pages-om-aurora-tsx" */),
  "component---src-pages-stod-oss-tsx": () => import("./../../../src/pages/stod-oss.tsx" /* webpackChunkName: "component---src-pages-stod-oss-tsx" */),
  "component---src-pages-styrelsen-tsx": () => import("./../../../src/pages/styrelsen.tsx" /* webpackChunkName: "component---src-pages-styrelsen-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

