import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useI18n from '../../../hooks/useI18n';
import type { Languages } from '../../../constants/languages';

export interface ISeoProps {
  lang: Languages;
  title: string;
  description?: string;
  author?: string;
  featuredImage?: string;
}

interface ISeoQuery {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      url: string;
    };
  };
  defaultFeaturedImage: {
    childImageSharp: {
      original: {
        src: string;
      };
    };
  };
}

const Seo = ({
  lang,
  title,
  description,
  author,
  featuredImage,
}: ISeoProps) => {
  const { alternates } = useI18n();

  const { site, defaultFeaturedImage }: ISeoQuery = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
            description
            url
          }
        }
        defaultFeaturedImage: file(
          relativePath: { eq: "images/featured.jpg" }
        ) {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    `
  );

  const {
    siteMetadata: {
      title: siteTitle,
      url,
      description: siteDescription,
    },
  } = site;

  description = description || siteDescription;
  author = author || siteTitle;
  featuredImage =
    featuredImage ||
    defaultFeaturedImage.childImageSharp.original.src;

  const alternateLanguages =
    alternates &&
    Object.keys(alternates).map((language) => (
      <link
        key={language}
        rel="alternate"
        hrefLang={language}
        href={`${url}${alternates[language]}`}
      />
    ));

  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'author',
      content: author,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: url + featuredImage,
    },
    {
      property: 'og:url',
      content: url,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      property: 'twitter:image',
      content: url + featuredImage,
    },
    {
      property: 'twitter:url',
      content: url,
    },
  ].map((metaItem) => (
    <meta key={metaItem.name || metaItem.property} {...metaItem} />
  ));

  return (
    <>
      <html lang={lang} />
      <title>
        {title} | {siteTitle}
      </title>
      {alternateLanguages}
      {meta}
    </>
  );
};

export default Seo;
