import { useContext } from 'react';
import stringUtil from '../utils/i18nString';
import {
  I18nContext,
  I18nContextProps,
} from '../contexts/I18nContext';
import { TranslationStrings } from '../constants/translationStrings';

export default function useI18n(): I18nContextProps & {
  string: (string: keyof TranslationStrings) => string;
} {
  const i18n = useContext(I18nContext);

  const string = (string: keyof TranslationStrings): string =>
    stringUtil(string, i18n.language);

  return { ...i18n, string };
}
