const THEMES = {
  disorderlyBlue: 'theme--disorderly-blue',
  radicalPink: 'theme--radical-pink',
  alertYellow: 'theme--alert-yellow',
  notSoNeutralWhite: 'theme--not-so-neutral-white',
  chaosBlack: 'theme--chaos-black',
  resistanceGreen: 'theme--resistance-green',
  red: 'theme--red',
  fckYouPurple: 'theme--fck-you-purple',
};

export default THEMES;
