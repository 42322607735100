import slugify from 'slugify';
import { DEFAULT_LANGUAGE, Languages } from '../constants/languages';
import getPagePath from './getPagePath';

/**
 * Turns a string into a URL-safe slug.
 * To be used when linking to and creating pages.
 *
 * @param slug - The string to be slugified.
 * @returns - The slugified path.
 *
 * @example slugifyPath('A Page Title') // returns 'a-page-title'
 */
export default function slugifyPath(slug: string): string {
  return slugify(slug, {
    lower: true,
    strict: true,
  });
}

/**
 * Slugifies and adds a base to the input string.
 * For use by posts/news articles.
 *
 * @param {string} slug - The string to be slugified.
 * @param {Languages} lang - The language of the base. Defaults to the default language.
 * @returns {string} - The slugified path.
 *
 * @example postPath('A Post Title', 'sv') //returns 'nyheter/a-post-title'
 */
export function postPath(
  slug: string,
  lang: Languages = DEFAULT_LANGUAGE
): string {
  const base = getPagePath('nyheter', lang);
  return `${base}/${slugifyPath(slug)}`;
}
