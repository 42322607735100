import React, { ReactNode } from 'react';
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image';
import CardTitle from './Title';
import * as style from './style.module.scss';

export interface ICardProps {
  children: ReactNode;
  image?: {
    data: IGatsbyImageData;
    alt?: string;
  };
  className?: string;
}

const Card = ({ children, image, className }: ICardProps) => {
  className = className ? ` ${className}` : '';

  const imageData = image?.data && getImage(image?.data);

  return (
    <article className={style.card + className}>
      {imageData && (
        <GatsbyImage
          className={style.card__image}
          image={imageData}
          alt={image.alt || ''}
        />
      )}
      {children}
    </article>
  );
};

export default Card;

export { CardTitle };
