/**
 * Turn a timestamp or date string into a locale string.
 *
 * @param date - The date to be converted.
 * @param locale - The locale of the output string.
 * @param format - The format of the output.
 * @returns - The localised date string.
 *
 * @example dateToString('2023-02-28T16:47:38.077Z', 'en-UK') // returns 'Tuesday, 28 February 2023'
 */
export default function dateToString(
  date: string,
  locale?: string,
  format?: Intl.DateTimeFormatOptions
): string {
  const settings: typeof format = format
    ? format
    : {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
  return new Date(date).toLocaleDateString(
    locale ? locale : 'sv-SE',
    settings
  );
}
