import React, { useEffect } from 'react';
import useToggle from '../../../hooks/useToggle';
import HeaderLogo from './Logo';
import HeaderNavButton from './Nav/Button';
import HeaderNavMenu from './Nav/Menu';
import * as style from './style.module.scss';

const Header = () => {
  const [menuIsActive, toggleMenuStatus, setMenuStatus] = useToggle();

  const setMenuInactive = () => setMenuStatus('inactive');

  if (typeof window !== 'undefined') {
    useEffect(setMenuInactive, [window.location.pathname]);

    useEffect(() => {
      const body = document.body;
      const activeClass = style.navigationMenuActive;

      menuIsActive
        ? body.classList.add(activeClass)
        : body.classList.remove(activeClass);
    }, [menuIsActive]);
  }

  return (
    <header className={style.header}>
      <div className={style.inner}>
        <HeaderLogo menuIsActive={menuIsActive} />
        <HeaderNavButton
          menuIsActive={menuIsActive}
          toggleMenuStatus={toggleMenuStatus}
        />
        <HeaderNavMenu
          menuIsActive={menuIsActive}
          setMenuInactive={setMenuInactive}
        />
      </div>
    </header>
  );
};

export default Header;
