import useI18n from './useI18n';
import { DEFAULT_LANGUAGE } from '../constants/languages';
import type Directus from '../types/directus';

type OmitTranslations<T> = Omit<T, 'translations'>;

type CollectionDataOutput<T> = T & {
  missingTranslation?: boolean;
};

/**
 * Use with collections fetched from our CMS (Directus).
 * Returns translations, if available/applicable,
 * otherwise return the value of the default language.
 *
 * @param collection - The collection to be used.
 * @param language - The language to use when checking for translations. Defaults to the site default language.
 * @returns - The translated collection.
 */
export default function useCollectionData<
  fields extends { translations?: Directus.Translations }
>(
  collection: fields,
  language: string = useI18n().language
): CollectionDataOutput<fields> {
  function getTranslation():
    | Directus.Translation<fields>
    | undefined {
    if (
      !Object.hasOwn(collection, 'translations') ||
      !Array.isArray(collection.translations) ||
      collection.translations.length < 1
    )
      return;

    return collection.translations.find(
      (translation) => translation?.languages_code?.code === language
    );
  }

  const translation = getTranslation();

  const outputData: CollectionDataOutput<fields> = {
    ...collection,
    missingTranslation: language !== DEFAULT_LANGUAGE,
  };

  if (translation && language !== DEFAULT_LANGUAGE) {
    Object.keys(collection).map((key) => {
      const outputDataKey = key as keyof OmitTranslations<fields>;
      const translationKey = key as keyof OmitTranslations<fields>;

      if (
        Object.hasOwn(translation, key) &&
        translation[translationKey]
      ) {
        outputData[outputDataKey] = translation[translationKey];
      }
    });

    outputData.missingTranslation = false;
  }

  return outputData;
}
