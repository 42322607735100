export default class CookieController {
  src = 'https://cdn.priv.center/pc/app.pid.js';
  script: HTMLScriptElement = document.createElement('script');

  constructor() {
    if (!process.env.GATSBY_TRUENDO_SITEID) return;

    this.script.async = true;
    this.script.id = 'truendoPrivacyPanel';
    this.script.type = 'text/javascript';
    this.script.setAttribute(
      'data-siteid',
      process.env.GATSBY_TRUENDO_SITEID as string
    );
    this.script.setAttribute('data-accessibility', 'true');
    this.script.setAttribute('data-transparency', 'false');
    this.script.src = this.src;

    document.body.appendChild(this.script);
  }

  onRouteUpdate(): void {
    if (!process.env.GATSBY_TRUENDO_SITEID) return;

    if (!this.script.id) return;

    const script: HTMLScriptElement | null =
      document.body.querySelector(`#${this.script.id}`);

    if (script) {
      script.src = '';
      script.src = this.src;
    }
  }
}
