/**
 * Function to get class name for text alignment.
 *
 * @todo This might be an unnecessary function, check if it  can be refactored/removed.
 *
 * @param alignment - Choose alignment for the returned class name.
 * @returns - The class name with apropriate aligment.
 *
 * @example textAlignment('right') // returns 'text__align--right'
 */
export default function textAlignment(
  alignment: 'center' | 'right' | 'left'
): string {
  return `text__align--${alignment}`;
}
