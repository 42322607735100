import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { Layout, Analytics } from './src/components/site';
import { I18nProvider } from './src/contexts/I18nContext';
import { ThemeProvider } from './src/contexts/ThemeContext';
import CookieController from './src/utils/CookieController';
import type PageContext from './src/types/page-context';
import '@fontsource/aileron/100.css';
import '@fontsource/aileron/400.css';
import '@fontsource/aileron/400-italic.css';
import '@fontsource/aileron/600.css';
import '@fontsource/aileron/600-italic.css';
import '@fontsource/aileron/800.css';
import './src/styles/main.scss';

const cookieController = new CookieController();

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const { language, alternateLangs } =
    props.pageContext as PageContext;
  return (
    <I18nProvider language={language} alternates={alternateLangs}>
      <ThemeProvider>
        <Layout>{element}</Layout>
        <Analytics />
      </ThemeProvider>
    </I18nProvider>
  );
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  cookieController.onRouteUpdate();
};
