import React from 'react';
import useI18n from '../../../../../hooks/useI18n';
import * as style from './style.module.scss';

export interface IHeaderNavButtonProps {
  menuIsActive: boolean;
  toggleMenuStatus: () => void;
}

const HeaderNavButton = ({
  menuIsActive,
  toggleMenuStatus,
}: IHeaderNavButtonProps) => {
  const { string } = useI18n();
  return (
    <button
      id="header-menubutton"
      className={`${style.headerNavButton} ${
        menuIsActive && style.active
      }`}
      onClick={toggleMenuStatus}
      title={string('COMPONENT_HEADERNAVBUTTON_TITLE')}
      aria-haspopup={true}
      aria-controls="header-menu"
    >
      <svg width="40" height="40" viewBox="0 0 40 22" fill="none">
        <rect
          className={style.rectTop}
          width="40"
          height="3"
          rx="1"
        />
        <rect
          className={style.rectMiddle}
          y="9"
          width="40"
          height="3"
          rx="1"
        />
        <rect
          className={style.rectBottom}
          y="19"
          width="40"
          height="3"
          rx="1"
        />
      </svg>
    </button>
  );
};

export default HeaderNavButton;
