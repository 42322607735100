import React, { ReactNode } from 'react';
import Masonry, { MasonryProps } from 'react-masonry-css';
import * as style from './style.module.scss';

export interface IGridProps {
  children: ReactNode;
  breakpoints: MasonryProps['breakpointCols'];
}

const Grid = ({ children, breakpoints }: IGridProps) => {
  const breakpointCols = breakpoints
    ? breakpoints
    : {
        default: 3,
        900: 2,
        600: 1,
      };

  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={style.grid}
      columnClassName={style.grid__column}
    >
      {children}
    </Masonry>
  );
};

export default Grid;
