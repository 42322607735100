import React, { ReactNode } from 'react';
import textAlignment from '../../utils/textAlignment';

export interface IParagraphProps {
  children: ReactNode;
  alignText: 'center' | 'right' | 'left';
}

const Paragraph = ({ children, alignText }: IParagraphProps) => {
  return (
    <p className={`content__paragraph ${textAlignment(alignText)}`}>
      {children}
    </p>
  );
};

export default Paragraph;
