import React from 'react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import useI18n from '../../hooks/useI18n';
import { DEFAULT_LANGUAGE } from '../../constants/languages';

const Link = (
  props: GatsbyLinkProps<unknown> | Readonly<GatsbyLinkProps<unknown>>
) => {
  const { language } = useI18n();
  const { ref, ...rest } = props;
  let { to } = props;

  if (to.startsWith('/')) {
    const prefix =
      language !== DEFAULT_LANGUAGE ? `/${language}` : '';

    to = prefix + to;
  }

  return (
    <GatsbyLink {...rest} to={to}>
      {props.children}
    </GatsbyLink>
  );
};

export default Link;
