import React, { ReactNode } from 'react';
import * as style from './style.module.scss';

export interface IHiddenTextProps {
  children: ReactNode;
}

const HiddenText = ({ children }: IHiddenTextProps) => {
  return <span className={style.hiddenText}>{children}</span>;
};

export default HiddenText;
