import React, { ReactNode } from 'react';
import textAlignment from '../../utils/textAlignment';

export interface IHeadingProps {
  children: ReactNode;
  priority: 1 | 2 | 3 | 4 | 5 | 6;
  alignText: 'center' | 'right' | 'left';
}

const Heading = ({
  children,
  priority,
  alignText,
}: IHeadingProps) => {
  if (typeof priority !== 'number' && priority > 6) priority = 1;

  const Tag: keyof JSX.IntrinsicElements = `h${priority}`;

  return (
    <Tag
      className={`content__heading--${priority} ${textAlignment(
        alignText
      )}`}
    >
      {children}
    </Tag>
  );
};

export default Heading;
