import React, { ReactNode } from 'react';
import { Link } from '../../typography';
import * as style from './style.module.scss';

export interface ICardTitleProps {
  children: ReactNode;
  uri?: string;
  newTab?: boolean;
  className?: string;
}

const CardTitle = ({
  uri,
  newTab,
  children,
  className,
}: ICardTitleProps) => {
  const target = newTab != null ? 'blank' : '';
  const rel = newTab != null ? 'noopener noreferrer' : '';

  return (
    <h1 className={`${style.card__title} ${className}`}>
      {uri ? (
        <Link
          to={uri}
          className={style.card__link}
          target={target}
          rel={rel}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </h1>
  );
};

export default CardTitle;
