import React, { ReactNode } from 'react';
import { Link } from '../typography';

export interface IButtonProps {
  children: ReactNode;
  to: string;
  color?: 'green' | 'red';
  style?: 'normal' | 'outline';
  isPrimary?: boolean;
  newTab?: boolean;
}

const Button = ({
  children,
  to,
  color,
  style = 'normal',
  isPrimary,
  newTab,
}: IButtonProps) => {
  const targetProps = newTab && {
    target: 'blank',
    rel: 'noopener',
  };

  let buttonColor = '';
  switch (color) {
    case 'green':
      buttonColor = 'button--green';
      break;
    case 'red':
      buttonColor = 'button--red';
      break;
    default:
      break;
  }

  const buttonStyle = style === 'outline' ? 'button--outline' : '';

  const buttonPrimary = isPrimary ? 'button--primary' : '';

  return (
    <Link
      to={to}
      {...targetProps}
      className={`button ${buttonColor} ${buttonStyle} ${buttonPrimary}`}
    >
      {children}
    </Link>
  );
};

export default Button;
