import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from '../../../../typography';
import { Button } from '../../../../ui';
import LanguageSelector from '../../../LanguageSelector';
import useCollectionData from '../../../../../hooks/useCollectionData';
import useI18n from '../../../../../hooks/useI18n';
import type Directus from '../../../../../types/directus';
import * as style from './style.module.scss';

export interface IHeaderNavMenuProps {
  menuIsActive: boolean;
  setMenuInactive: () => void;
}

export interface IHeaderMenuQuery {
  header_menu: {
    header_menu_items: Array<{
      id: string;
      label: string;
      slug: string;
      translations?: Directus.Translations<{
        label: string;
        slug: string;
      }>;
      children?: Array<{
        id: string;
        label: string;
        link: string;
        translations?: Directus.Translations<{
          label: string;
          link: string;
        }>;
      }>;
    }>;
    call_to_action_label?: string;
    call_to_action_link?: string;
    translations?: Directus.Translations<{
      call_to_action_label?: string;
      call_to_action_link?: string;
    }>;
  };
}

const HeaderNavMenu = ({
  menuIsActive,
  setMenuInactive,
}: IHeaderNavMenuProps) => {
  const { string } = useI18n();

  const data: Directus.Query<IHeaderMenuQuery> = useStaticQuery(
    graphql`
      query HeaderNavMenuQuery {
        directus {
          header_menu {
            header_menu_items {
              id
              label
              slug
              translations(
                filter: {
                  label: { _nempty: true }
                  slug: { _nempty: true }
                }
              ) {
                languages_code {
                  code
                }
                label
                slug
              }
              children {
                id
                label
                link
                translations(
                  filter: {
                    label: { _nempty: true }
                    link: { _nempty: true }
                  }
                ) {
                  languages_code {
                    code
                  }
                  label
                  link
                }
              }
            }
            call_to_action_label
            call_to_action_link
            translations(
              filter: {
                call_to_action_link: { _nempty: true }
                call_to_action_label: { _nempty: true }
              }
            ) {
              languages_code {
                code
              }
              call_to_action_link
              call_to_action_label
            }
          }
        }
      }
    `
  );

  const { call_to_action_label, call_to_action_link } =
    useCollectionData(data.directus.header_menu);

  /**
   * For some reason the submenu doesn't close when
   * a link is clicked when in production (in dev this works fine).
   * Manually unset active element to resolve this.
   */
  const handleOnClick = () => {
    (document.activeElement as HTMLElement).blur();
  };

  const items = data.directus.header_menu.header_menu_items
    ? data.directus.header_menu.header_menu_items.map((menuItem) => {
        const { label, slug } = useCollectionData(menuItem);

        const childItems = menuItem.children?.map((child) => {
          const { link, label } = useCollectionData(child);
          return (
            <li key={link} role="presentation">
              <Link role="menuitem" to={link} onClick={handleOnClick}>
                {label}
              </Link>
            </li>
          );
        });

        return (
          <li
            key={menuItem.id}
            role="presentation"
            className={style.headerNav__listItem}
          >
            <span
              role="menuitem"
              className={style.headerNav__listItemInner}
            >
              <Link to={slug} onClick={handleOnClick}>
                {label}
              </Link>
              {childItems && childItems[0] && (
                <ul
                  role="menu"
                  aria-label={label}
                  className={style.children}
                >
                  {childItems}
                </ul>
              )}
            </span>
          </li>
        );
      })
    : null;

  return (
    <>
      <nav
        id="header-menu"
        className={`${style.headerNav} ${
          menuIsActive && style.active
        }`}
        aria-label={string('COMPONENT_HEADERNAVMENU_NAV_ARIA_LABEL')}
      >
        <div className={style.headerNav__inner}>
          <ul
            role="menubar"
            aria-label={string(
              'COMPONENT_HEADERNAVMENU_UL_ARIA_LABEL'
            )}
            className={style.headerNav__list}
          >
            {items}
          </ul>
          <div className={style.headerNav__languages}>
            <LanguageSelector />
          </div>
          {call_to_action_label && call_to_action_link && (
            <div className={style.headerNav__cta}>
              <Button to={call_to_action_link} newTab isPrimary>
                {call_to_action_label}
              </Button>
            </div>
          )}
        </div>
      </nav>
      <div
        className={`${style.headerNav__backdrop} ${
          menuIsActive && style.active
        }`}
        onClick={setMenuInactive}
      />
    </>
  );
};

export default HeaderNavMenu;
