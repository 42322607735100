export default function truncate(
  str: string,
  max: number,
  suffix: string
): string {
  return str.length < max
    ? str
    : `${str.slice(
        0,
        str.slice(0, max - suffix.length).lastIndexOf(' ')
      )}${suffix}`;
}
