import { DEFAULT_LANGUAGE, LANGUAGES } from '../constants/languages';
import THEMES from '../constants/themes';
import getPagePath from './getPagePath';

/**
 * Gets theme class name based on path.
 *
 * @param path - The path whose theme is to be retrieved.
 * @returns - The theme class name.
 *
 * @example getThemeFromPath('/stod-oss'); // returns 'theme--alert-yellow'
 */
export default function getThemeFromPath(path: string): string {
  let theme = THEMES.chaosBlack;

  const pages: { [key: string]: keyof typeof THEMES } = {
    'stod-oss': 'alertYellow',
    'om-aurora': 'fckYouPurple',
    styrelsen: 'disorderlyBlue',
    nyheter: 'radicalPink',
    juridik: 'resistanceGreen',
    faq: 'red',
  };

  for (const [key, value] of Object.entries(pages)) {
    const pageComponent = key;
    const pageTheme = value;

    if (
      path.startsWith(
        getPagePath(pageComponent, DEFAULT_LANGUAGE) + '/'
      )
    ) {
      theme = THEMES[pageTheme];
      break;
    }

    for (const lang of LANGUAGES) {
      if (
        path
          .slice(3)
          .startsWith(getPagePath(pageComponent, lang) + '/')
      ) {
        theme = THEMES[pageTheme];
        break;
      }
    }
  }

  return theme;
}
