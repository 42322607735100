import React from 'react';
import { Link } from '../../../typography';
import useI18n from '../../../../hooks/useI18n';
import LogoHorizontalSvg from '../../../../assets/vectors/logo-filled-horizontal.svg';
import * as style from './style.module.scss';

export interface IHeaderLogoProps {
  menuIsActive: boolean;
}

const HeaderLogo = ({ menuIsActive }: IHeaderLogoProps) => {
  const { string } = useI18n();

  return (
    <Link
      className={`${style.headerLogo} ${
        menuIsActive && style.active
      }`}
      to="/"
      title={string('COMPONENT_HEADER_LOGO_LINK_TITLE')}
    >
      <LogoHorizontalSvg />
    </Link>
  );
};

export default HeaderLogo;
