import { Languages } from './languages';

export interface TranslationStrings {
  // Common

  COMMON_PRIVACY_POLICY: string;

  // Pages

  PAGE_NOT_FOUND_SEO_TITLE: string;

  PAGE_INDEX_SEO_TITLE: string;
  PAGE_INDEX_FOCUS_ITEM_LINK_TEXT: string;
  PAGE_INDEX_POSTS_TITLE: string;
  PAGE_INDEX_POSTS_BUTTON: string;

  PAGE_PRIVACY_POLICY_SEO_DESCRIPTION: string;

  PAGE_NEWS_SEO_TITLE: string;
  PAGE_NEWS_SEO_DESCRIPTION: string;
  PAGE_NEWS_TITLE: string;

  PAGE_FAQ_INTRO: string;

  // Page paths

  PAGE_PATH_INDEX: `/${string}`;
  PAGE_PATH_404: `/${string}`;
  PAGE_PATH_PRIVACY_POLICY: `/${string}`;
  PAGE_PATH_LEGAL: `/${string}`;
  PAGE_PATH_NEWS: `/${string}`;
  PAGE_PATH_NEWSLETTER: `/${string}`;
  PAGE_PATH_ABOUT_US: `/${string}`;
  PAGE_PATH_SUPPORT_US: `/${string}`;
  PAGE_PATH_BOARD: `/${string}`;
  PAGE_PATH_FAQ: `/${string}`;

  // Components

  COMPONENT_FOOTER_POLICY_TEXT: string;
  COMPONENT_FOOTER_SETTINGS_TEXT: string;
  COMPONENT_FOOTER_SETTINGS_TITLE: string;

  COMPONENT_HEADER_LOGO_LINK_TITLE: string;

  COMPONENT_HEADERNAVBUTTON_TITLE: string;

  COMPONENT_HEADERNAVMENU_NAV_ARIA_LABEL: string;
  COMPONENT_HEADERNAVMENU_UL_ARIA_LABEL: string;

  COMPONENT_LANGUAGE_SELECTOR_TITLE: string;
  COMPONENT_LANGUAGE_SELECTOR_TITLE_DISABLED: string;

  COMPONENT_HOME_HERO_TITLE_FIRST: string;
  COMPONENT_HOME_HERO_TITLE_LAST: string;

  COMPONENT_NEWSLETTER_POST_CONSENT_TEXT: string;
  COMPONENT_NEWSLETTER_SUCCESS_TITLE: string;
  COMPONENT_NEWSLETTER_SUCCESS_MESSAGE: string;
  COMPONENT_NEWSLETTER_ERROR_CLIENT_TITLE: string;
  COMPONENT_NEWSLETTER_ERROR_CLIENT_TEXT: string;
  COMPONENT_NEWSLETTER_ERROR_SERVER_TITLE: string;
  COMPONENT_NEWSLETTER_ERROR_SERVER_MESSAGE: string;
  COMPONENT_NEWSLETTER_ERROR_OTHER_TITLE: string;
  COMPONENT_NEWSLETTER_ERROR_OTHER_MESSAGE: string;
  COMPONENT_NEWSLETTER_INPUT_FIRST_NAME: string;
  COMPONENT_NEWSLETTER_INPUT_FIRST_NAME_INVALID: string;
  COMPONENT_NEWSLETTER_INPUT_LAST_NAME: string;
  COMPONENT_NEWSLETTER_INPUT_EMAIL: string;
  COMPONENT_NEWSLETTER_INPUT_EMAIL_INVALID: string;
  COMPONENT_NEWSLETTER_CONSENT_FIRST: string;
  COMPONENT_NEWSLETTER_CONSENT_LAST: string;
  COMPONENT_NEWSLETTER_SUBMIT_BUTTON_SEND: string;
  COMPONENT_NEWSLETTER_SUBMIT_BUTTON_LOADING: string;

  COMPONENT_MODALWINDOW_BUTTON_TEXT: string;

  COMPONENT_NOTFOUNDTITLE_SUB_TITLE: string;

  COMPONENT_NOTFOUNDCONTENT_DESCRIPTION: string;
  COMPONENT_NOTFOUNDCONTENT_BUTTON: string;

  COMPONENT_SOCIAL_LINK_TITLE: string;
  COMPONENT_SOCIAL_TITLE: string;

  COMPONENT_LEGAL_DOC_CARD_BUTTON: string;
  COMPONENT_LEGAL_DOC_CARD_BUTTON_HIDDEN: string;
}

type Translations = {
  [key in Languages]: TranslationStrings;
};

const TRANSLATION_STRINGS: Translations = {
  sv: {
    // Common

    COMMON_PRIVACY_POLICY: 'integritetspolicy',

    // Pages

    PAGE_NOT_FOUND_SEO_TITLE: '404: Sidan hittades inte',

    PAGE_INDEX_SEO_TITLE: 'Vi är Aurora, nu stämmer vi staten!',
    PAGE_INDEX_FOCUS_ITEM_LINK_TEXT: 'Läs mer',
    PAGE_INDEX_POSTS_TITLE: 'Senaste från Auroramålet',
    PAGE_INDEX_POSTS_BUTTON: 'Visa fler',

    PAGE_PRIVACY_POLICY_SEO_DESCRIPTION:
      'Här hittar du Auroras integritetspolicy',

    PAGE_NEWS_SEO_TITLE: 'Nyheter',
    PAGE_NEWS_SEO_DESCRIPTION: 'Senaste från Auroramålet',
    PAGE_NEWS_TITLE: 'Nyheter',

    PAGE_FAQ_INTRO: 'Svar på vanliga frågor om att stämma staten',

    // Page paths

    PAGE_PATH_INDEX: '/',
    PAGE_PATH_404: '/404',
    PAGE_PATH_PRIVACY_POLICY: '/integritetspolicy',
    PAGE_PATH_LEGAL: '/juridik',
    PAGE_PATH_NEWS: '/nyheter',
    PAGE_PATH_NEWSLETTER: '/nyhetsbrev',
    PAGE_PATH_ABOUT_US: '/om-aurora',
    PAGE_PATH_SUPPORT_US: '/stod-oss',
    PAGE_PATH_BOARD: '/styrelsen',
    PAGE_PATH_FAQ: '/faq',

    // Components

    COMPONENT_FOOTER_POLICY_TEXT: 'Integritetspolicy',
    COMPONENT_FOOTER_SETTINGS_TEXT: 'Ändra kakor',
    COMPONENT_FOOTER_SETTINGS_TITLE:
      'Klicka här för att se och ändra dina inställningar för cookies och integritet',

    COMPONENT_HEADER_LOGO_LINK_TITLE: 'Hem',

    COMPONENT_HEADERNAVBUTTON_TITLE: 'Visa meny',

    COMPONENT_HEADERNAVMENU_NAV_ARIA_LABEL: 'Huvudnavigering',
    COMPONENT_HEADERNAVMENU_UL_ARIA_LABEL: 'Huvudmeny',

    COMPONENT_LANGUAGE_SELECTOR_TITLE: 'Byt språk',
    COMPONENT_LANGUAGE_SELECTOR_TITLE_DISABLED:
      'Inga andra språk tillgängliga',

    COMPONENT_HOME_HERO_TITLE_FIRST: 'Nu stämmer',
    COMPONENT_HOME_HERO_TITLE_LAST: 'vi staten!',

    COMPONENT_NEWSLETTER_POST_CONSENT_TEXT:
      'Jag godkänner Auroras integritetspolicy samt att Aurora lagrar mina personuppgifter för mailutskick',
    COMPONENT_NEWSLETTER_SUCCESS_TITLE: 'Tack!',
    COMPONENT_NEWSLETTER_SUCCESS_MESSAGE:
      'Du har fått ett mail till din inkorg. Klicka på länken i mailet för att bekräfta att du vill prenumerera!',
    COMPONENT_NEWSLETTER_ERROR_CLIENT_TITLE: 'Oops!',
    COMPONENT_NEWSLETTER_ERROR_CLIENT_TEXT:
      'Ogiltig data har angetts. Prova att fylla i formuläret igen!',
    COMPONENT_NEWSLETTER_ERROR_SERVER_TITLE: 'Oj då!',
    COMPONENT_NEWSLETTER_ERROR_SERVER_MESSAGE: 'Tekniskt fel.',
    COMPONENT_NEWSLETTER_ERROR_OTHER_TITLE: 'Attans!',
    COMPONENT_NEWSLETTER_ERROR_OTHER_MESSAGE: 'Okänt fel.',
    COMPONENT_NEWSLETTER_INPUT_FIRST_NAME: 'Förnamn',
    COMPONENT_NEWSLETTER_INPUT_FIRST_NAME_INVALID:
      'Ange ett giltigt förnamn.',
    COMPONENT_NEWSLETTER_INPUT_LAST_NAME: 'Efternamn',
    COMPONENT_NEWSLETTER_INPUT_EMAIL: 'E-post',
    COMPONENT_NEWSLETTER_INPUT_EMAIL_INVALID:
      'Ange en giltig mailadress.',
    COMPONENT_NEWSLETTER_CONSENT_FIRST: 'Jag godkänner Auroras',
    COMPONENT_NEWSLETTER_CONSENT_LAST:
      'samt att Aurora lagrar mina personuppgifter för mailutskick',
    COMPONENT_NEWSLETTER_SUBMIT_BUTTON_SEND: 'Skicka',
    COMPONENT_NEWSLETTER_SUBMIT_BUTTON_LOADING: 'Laddar...',

    COMPONENT_MODALWINDOW_BUTTON_TEXT: 'Okej',

    COMPONENT_NOTFOUNDTITLE_SUB_TITLE: 'Sidan hittades inte',

    COMPONENT_NOTFOUNDCONTENT_DESCRIPTION:
      'Sidan du letar efter har antingen flyttats eller raderats.',
    COMPONENT_NOTFOUNDCONTENT_BUTTON: 'Gå till startsidan',

    COMPONENT_SOCIAL_LINK_TITLE: 'Följ oss på',
    COMPONENT_SOCIAL_TITLE: 'Följ Auroramålet',

    COMPONENT_LEGAL_DOC_CARD_BUTTON: 'Visa dokument',
    COMPONENT_LEGAL_DOC_CARD_BUTTON_HIDDEN: 'et:',
  },
  en: {
    // Common

    COMMON_PRIVACY_POLICY: 'privacy policy',

    // Pages

    PAGE_NOT_FOUND_SEO_TITLE: '404: Page not found',

    PAGE_INDEX_SEO_TITLE: "We are Aurora, and we're suing the state!",
    PAGE_INDEX_FOCUS_ITEM_LINK_TEXT: 'Read more',
    PAGE_INDEX_POSTS_TITLE: 'Latest from Aurora',
    PAGE_INDEX_POSTS_BUTTON: 'Show more',

    PAGE_PRIVACY_POLICY_SEO_DESCRIPTION:
      "Here you can find Aurora's privacy policy",

    PAGE_NEWS_SEO_TITLE: 'News',
    PAGE_NEWS_SEO_DESCRIPTION: 'Latest from Aurora',
    PAGE_NEWS_TITLE: 'News',

    PAGE_FAQ_INTRO:
      'Responses to common questions about suing the state',

    // Page paths

    PAGE_PATH_INDEX: '/',
    PAGE_PATH_404: '/404',
    PAGE_PATH_PRIVACY_POLICY: '/privacy-policy',
    PAGE_PATH_LEGAL: '/legal',
    PAGE_PATH_NEWS: '/news',
    PAGE_PATH_NEWSLETTER: '/newsletter',
    PAGE_PATH_ABOUT_US: '/about-us',
    PAGE_PATH_SUPPORT_US: '/support-us',
    PAGE_PATH_BOARD: '/board',
    PAGE_PATH_FAQ: '/faq',

    // Components

    COMPONENT_FOOTER_POLICY_TEXT: 'Privacy policy',
    COMPONENT_FOOTER_SETTINGS_TEXT: 'Cookie settings',
    COMPONENT_FOOTER_SETTINGS_TITLE:
      'Click here to view or change your cookie and privacy preferences',

    COMPONENT_HEADER_LOGO_LINK_TITLE: 'Home',

    COMPONENT_HEADERNAVBUTTON_TITLE: 'Show menu',

    COMPONENT_HEADERNAVMENU_NAV_ARIA_LABEL: 'Main navigation',
    COMPONENT_HEADERNAVMENU_UL_ARIA_LABEL: 'Main menu',

    COMPONENT_LANGUAGE_SELECTOR_TITLE: 'Change language',
    COMPONENT_LANGUAGE_SELECTOR_TITLE_DISABLED:
      'No other languages available',

    COMPONENT_HOME_HERO_TITLE_FIRST: "Let's sue",
    COMPONENT_HOME_HERO_TITLE_LAST: 'the state!',

    COMPONENT_NEWSLETTER_POST_CONSENT_TEXT:
      "I agree to Aurora's privacy policy and that Aurora stores my personal information for the puropose of sending me emails",
    COMPONENT_NEWSLETTER_SUCCESS_TITLE: 'Thank you!',
    COMPONENT_NEWSLETTER_SUCCESS_MESSAGE:
      'You have received a email to your inbox. Klick on the link in the email to confirm your subscription!',
    COMPONENT_NEWSLETTER_ERROR_CLIENT_TITLE: 'Oops!',
    COMPONENT_NEWSLETTER_ERROR_CLIENT_TEXT:
      'Invalid data has been submitted. Please try again!',
    COMPONENT_NEWSLETTER_ERROR_SERVER_TITLE: 'Oh dear!',
    COMPONENT_NEWSLETTER_ERROR_SERVER_MESSAGE: 'Technical error.',
    COMPONENT_NEWSLETTER_ERROR_OTHER_TITLE: 'Darn it!',
    COMPONENT_NEWSLETTER_ERROR_OTHER_MESSAGE: 'Unknown error.',
    COMPONENT_NEWSLETTER_INPUT_FIRST_NAME: 'First name',
    COMPONENT_NEWSLETTER_INPUT_FIRST_NAME_INVALID:
      'Please provide a valid name.',
    COMPONENT_NEWSLETTER_INPUT_LAST_NAME: 'Last name',
    COMPONENT_NEWSLETTER_INPUT_EMAIL: 'Email',
    COMPONENT_NEWSLETTER_INPUT_EMAIL_INVALID:
      'Please provide a valid email address',
    COMPONENT_NEWSLETTER_CONSENT_FIRST: "I agree to Aurora's",
    COMPONENT_NEWSLETTER_CONSENT_LAST:
      'and that Aurora stores my personal information for the purpose of sending me emails',
    COMPONENT_NEWSLETTER_SUBMIT_BUTTON_SEND: 'Send',
    COMPONENT_NEWSLETTER_SUBMIT_BUTTON_LOADING: 'Loading...',

    COMPONENT_MODALWINDOW_BUTTON_TEXT: 'Okay',

    COMPONENT_NOTFOUNDTITLE_SUB_TITLE: 'Page not found',

    COMPONENT_NOTFOUNDCONTENT_DESCRIPTION:
      "The page you're looking for has either been moved or deleted.",
    COMPONENT_NOTFOUNDCONTENT_BUTTON: 'Go to home page',

    COMPONENT_SOCIAL_LINK_TITLE: 'Follow us on',
    COMPONENT_SOCIAL_TITLE: 'Follow Auroramålet',

    COMPONENT_LEGAL_DOC_CARD_BUTTON: 'Open document',
    COMPONENT_LEGAL_DOC_CARD_BUTTON_HIDDEN: ':',
  },
};

export default TRANSLATION_STRINGS;
