import React, { ReactNode } from 'react';
import { ThemeConsumer } from '../../../contexts/ThemeContext';
import Header from '../Header';
import Footer from '../Footer';
import Noise from '../../../assets/vectors/noise.svg';
import * as style from './style.module.scss';

export interface ILayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
  return (
    <ThemeConsumer>
      {(theme) => (
        <>
          <div className={`${theme} ${style.site}`}>
            <span className={style.noise}>
              <Noise />
            </span>
            <Header />
            <main className={style.main}>{children}</main>
            <Footer />
          </div>
        </>
      )}
    </ThemeConsumer>
  );
};

export default Layout;
