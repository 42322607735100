import React from 'react';
import * as style from './style.module.scss';

export interface IContentProps {
  source: string;
  background?: boolean;
}

const Content = ({ source, background }: IContentProps) => {
  const classes = background
    ? `${style.content} ${style.background}`
    : style.content;

  return (
    <div
      className={`content ${classes}`}
      dangerouslySetInnerHTML={{ __html: source }}
    />
  );
};

export default Content;
