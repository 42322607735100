import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faVimeo,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useI18n from '../../../hooks/useI18n';
import type Directus from '../../../types/directus';
import * as style from './style.module.scss';

const ICONS = [
  { id: 'instagram', icon: faInstagram },
  { id: 'facebook', icon: faFacebook },
  { id: 'twitter', icon: faTwitter },
  { id: 'tiktok', icon: faTiktok },
  { id: 'youtube', icon: faYoutube },
  { id: 'vimeo', icon: faVimeo },
];

interface ISocialLink {
  id: string;
  type: string;
  url: string;
}

interface ISocialQuery {
  social_media: Array<ISocialLink>;
}

const Social = () => {
  const { string } = useI18n();

  const data: Directus.Query<ISocialQuery> = useStaticQuery(graphql`
    query SocialQuery {
      directus {
        social_media {
          id
          type
          url
        }
      }
    }
  `);

  const { social_media } = data.directus;

  const links = social_media.map(({ id, type, url }) => {
    const linkTitle = type.charAt(0).toUpperCase() + type.slice(1);
    const { icon: linkIcon } = ICONS.filter(
      ({ id }) => id === type
    )[0];

    return (
      <li key={id} className={style.social__listItem}>
        <a
          title={`${string(
            'COMPONENT_SOCIAL_LINK_TITLE'
          )} ${linkTitle}`}
          href={url}
          target="blank"
          rel="noreferrer external"
        >
          <FontAwesomeIcon icon={linkIcon} />
        </a>
      </li>
    );
  });

  return (
    <div className={style.social}>
      <h2 className={style.social__title}>
        {string('COMPONENT_SOCIAL_TITLE')}
      </h2>
      <ul className={style.social__list}>{links}</ul>
    </div>
  );
};

export default Social;
