import React from 'react';
import { Link } from '../../typography';
import { Social } from '../../shared';
import useI18n from '../../../hooks/useI18n';
import LogoMark from '../../../assets/vectors/logomark-empty.svg';
import WordMark from '../../../assets/vectors/wordmark.svg';
import * as style from './style.module.scss';

declare global {
  interface Window {
    Truendo: {
      [key: string]: () => void;
    };
  }
}

const Footer = () => {
  const { string } = useI18n();

  return (
    <footer className={style.footer}>
      <div className={style.footer__inner}>
        <figure className={style.footer__logo}>
          <LogoMark />
          <WordMark />
        </figure>
        <Social />
        <div className={style.footer__flex}>
          <span className={style.footer__copy}>
            © 2022 Auroramålet
          </span>
          <span className={style.footer__privacy}>
            <Link
              className={style.footer__privacyPolicy}
              to={string('PAGE_PATH_PRIVACY_POLICY')}
            >
              {string('COMPONENT_FOOTER_POLICY_TEXT')}
            </Link>
            <a
              role="button"
              className={style.footer__privacySettings}
              onClick={() => window.Truendo.openPrivacyPanel()}
              title={string('COMPONENT_FOOTER_SETTINGS_TITLE')}
            >
              {string('COMPONENT_FOOTER_SETTINGS_TEXT')}
            </a>
          </span>
        </div>
        <span className={style.footer__credits}>
          website by <a href="https://smn.codes">Simon</a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
