import React, { createContext, ReactNode } from 'react';
import { useLocation } from '@reach/router';
import getThemeFromPath from '../utils/getThemeFromPath';

export const ThemeContext = createContext('');

export interface IThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: IThemeProviderProps) => {
  const { pathname } = useLocation();

  const theme = getThemeFromPath(pathname);

  return (
    <ThemeContext.Provider value={theme as string}>
      {children}
    </ThemeContext.Provider>
  );
};

export interface IThemeConsumerProps {
  children: (theme: string) => ReactNode;
}

export const ThemeConsumer = ({ children }: IThemeConsumerProps) => (
  <ThemeContext.Consumer>{children}</ThemeContext.Consumer>
);
