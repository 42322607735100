import React from 'react';
import { Link } from 'gatsby';
import {
  Root,
  Trigger,
  Portal,
  Content,
  Item,
} from '@radix-ui/react-dropdown-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons';
import { HiddenText } from '../../typography';
import useI18n from '../../../hooks/useI18n';
import {
  Languages,
  LANGUAGE_NAMES,
} from '../../../constants/languages';
import * as style from './style.module.scss';

const LanguageSelector = () => {
  const { language, alternates, string } = useI18n();

  const alternateLangs = {
    [language]: language,
    ...alternates,
  };

  const isDisabled = Object.keys(alternates || {}).length < 1;

  const languages = Object.keys(alternateLangs)
    .sort()
    .map((lang) => {
      return (
        <Item
          key={lang}
          className={style.language}
          asChild={lang !== language}
          disabled={lang === language}
          title={
            lang !== language
              ? LANGUAGE_NAMES[lang as Languages]
              : undefined
          }
        >
          {lang !== language ? (
            <Link to={alternateLangs[lang]}>
              {LANGUAGE_NAMES[lang as Languages]}
            </Link>
          ) : (
            LANGUAGE_NAMES[lang as Languages]
          )}
        </Item>
      );
    });
  return (
    <Root>
      <Trigger
        className={style.languageSelector__button}
        title={
          isDisabled
            ? string('COMPONENT_LANGUAGE_SELECTOR_TITLE_DISABLED')
            : string('COMPONENT_LANGUAGE_SELECTOR_TITLE')
        }
        disabled={isDisabled}
      >
        <FontAwesomeIcon
          className={style.languages}
          icon={faLanguage}
        />
        <HiddenText>
          {string('COMPONENT_LANGUAGE_SELECTOR_TITLE')}
        </HiddenText>
        <FontAwesomeIcon
          className={style.chevron}
          icon={faChevronDown}
        />
      </Trigger>
      <Portal>
        <Content
          className={style.languageSelector__dropDown}
          align="center"
        >
          {languages}
        </Content>
      </Portal>
    </Root>
  );
};

export default LanguageSelector;
