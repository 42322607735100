import { Languages } from '../constants/languages';
import { TranslationStrings } from '../constants/translationStrings';
import i18nString from './i18nString';

/**
 * Returns the path for a given page in the specified language.
 *
 * @param path - The path of the page to retrieve.
 * @param lang - The language of the page to retrieve.
 * @returns - The path of the page in the specified language.
 *
 * @example getPagePath('nyheter', 'en'); // returns '/en/news'
 */
export default function getPagePath(
  path: string,
  lang: Languages
): string {
  const pageComponents: {
    [key: string]: keyof TranslationStrings;
  } = {
    index: 'PAGE_PATH_INDEX',
    '404': 'PAGE_PATH_404',
    integritetspolicy: 'PAGE_PATH_PRIVACY_POLICY',
    juridik: 'PAGE_PATH_LEGAL',
    nyheter: 'PAGE_PATH_NEWS',
    nyhetsbrev: 'PAGE_PATH_NEWSLETTER',
    'om-aurora': 'PAGE_PATH_ABOUT_US',
    'stod-oss': 'PAGE_PATH_SUPPORT_US',
    styrelsen: 'PAGE_PATH_BOARD',
    faq: 'PAGE_PATH_FAQ',
  };

  return i18nString(
    pageComponents[path as keyof typeof pageComponents],
    lang
  );
}
