import React from 'react';
import { Card, CardTitle } from '../ui';
import useI18n from '../../hooks/useI18n';
import dateToString from '../../utils/dateToString';
import { postPath } from '../../utils/slugifyPath';
import truncate from '../../utils/truncate';
import type Directus from '../../types/directus';

export interface IPostCardProps {
  id: string;
  status: Directus.Status;
  date_created: string;
  slug?: string;
  title: string;
  intro?: string;
  featured_image?: Directus.Image;
  featured_image_alt?: string;
  translations: Directus.Translations<{
    slug?: string;
    title: string;
    intro?: string;
    featured_image_alt?: string;
  }>;
}

const PostCard = ({
  id,
  date_created,
  slug,
  title,
  intro,
  featured_image,
  featured_image_alt,
}: IPostCardProps) => {
  const { language } = useI18n();

  slug = postPath(slug ?? title, language);

  return (
    <Card
      key={id}
      image={
        featured_image?.imageFile?.childImageSharp &&
        featured_image_alt
          ? {
              data: featured_image?.imageFile.childImageSharp
                .gatsbyImageData,
              alt: featured_image_alt,
            }
          : undefined
      }
    >
      <CardTitle uri={slug}>{title}</CardTitle>
      <p>
        <em>
          <time dateTime={date_created}>
            {dateToString(date_created, language)}
          </time>
        </em>
      </p>
      <p>{intro && truncate(intro, 200, ' ...')}</p>
    </Card>
  );
};

export default PostCard;
