import { useCallback, useState } from 'react';

export default function useToggle(
  initialState = false
): [boolean, () => void, (status: 'active' | 'inactive') => void] {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);

  const setStatus = useCallback((status: 'active' | 'inactive') => {
    setState(status === 'active');
  }, []);

  return [state, toggle, setStatus];
}
