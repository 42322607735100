import { Languages, LANGUAGES } from '../constants/languages';
import TRANSLATION_STRINGS, {
  TranslationStrings,
} from '../constants/translationStrings';

/**
 * Retrive static strings in all site supported languages.
 *
 * @param string - The identifier for what string to retrive.
 * @param language - The language from which the string should be.
 * @returns - The string.
 *
 * @example i18nString('PAGE_NEWS_SEO_DESCRIPTION', 'sv') // returns 'Senaste från Auroramålet'
 */
export default function i18nString(
  string: keyof TranslationStrings,
  language: Languages
): string {
  const translationStrings =
    TRANSLATION_STRINGS[
      LANGUAGES.find((lang) => language === lang) || 'sv'
    ];

  return translationStrings[string];
}
