type LanguageCodes = ['sv', 'en'];

export type Languages = LanguageCodes[number];

export const DEFAULT_LANGUAGE = 'sv';

export const LANGUAGES: LanguageCodes = [DEFAULT_LANGUAGE, 'en'];

export const LANGUAGE_NAMES: { [key in Languages]: string } = {
  sv: 'Svenska',
  en: 'English',
};
